import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { firebase, auth, db } from '../firebaseConfig/index.js'

Vue.use(Vuex)

 // handle page reload
auth.onAuthStateChanged(user => {
  Vue.set(store.state, 'profileReady', false)
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('getUserProfile')
  } else {
    store.dispatch('clearData')
  }
})

export const store = new Vuex.Store({
    state: {
      teams: [],
      teamId: null,
      team: {},
      teamIds: [],
      game: {},
      currentGameId: null,
      currentUser: null,
      currentTeamId: null,
      userProfile: {},
      profileReady: false,
      nav: {}
    },
    mutations: {
      updateNav (state, val) {
        Vue.set(state, 'nav', val)
      },
      setCurrentTeamId(state, val) {
        Vue.set(state, 'currentTeamId', val)
      },
      setCurrentGameId(state, val) {
        Vue.set(state, 'currentGameId', val)
      },
      setCurrentTeam(state, val) {
        Vue.set(state, 'team', val)
      },
      setCurrentUser(state, val) {
        Vue.set(state, 'currentUser', val)
      },
      setUserProfile(state, val) {
        Vue.set(state, 'userProfile', val)
        Vue.set(state, 'profileReady', true)
      },
      setTeamIds(state, val) {
        Vue.set(state, 'teamIds', val)
      },
      profileReady(state, val) {
        Vue.set(state, 'profileReady', val)
      },
      ...vuexfireMutations
    },
    actions: {
      bindTeamData: firestoreAction(({ bindFirestoreRef, state }) => {
        return bindFirestoreRef('team', db.collection('teams').doc(state.currentTeamId))
      }),
      /* eslint-disable no-unused-vars */
      getTeamByTsId ({ state }, tsId) {
        return db
          .collection('teams')
          .where('tsId', '==', tsId)
          .get()
          .then(snapshot => {
            if (snapshot.empty) {
              return null
            }
            const docs = snapshot.docs.map(doc => doc.data())
            return docs[0]
          })
      },
      /* eslint-disable no-unused-vars */
      getTeamsByIds ({ state }, idsArray) {
        return db
          .collection('teams')
          .where('id', 'in', idsArray)
          .get()
          .then(snapshot => {
            if (snapshot.empty) {
              return null
            }
            const docs = snapshot.docs.map(doc => doc.data())
            return docs
          })
      },
      addTeam: firestoreAction(({ state }) => {
        return db
          .collection('teams')
          .add(state.team)
          .then(docRef => {
            return db
              .collection('teams')
              .doc(docRef.id)
              .update({ id: docRef.id})
              .then(() => {
                console.log('Team created with id ', docRef.id)
                return docRef.id
              })
          })
      }),
      saveTeam: firestoreAction(({ state }) => {
        const team = { ...state.team }
        return db
          .collection('teams')
          .doc(state.team.id)
          .set(team)
          .then(() => {
            console.log('Team updated.')
          })
      }),
      getUserProfile: firestoreAction(({ commit, state }) => {
        return db
          .collection('users')
          .doc(state.currentUser.uid)
          .get()
          .then(snapshot => {
            const doc = snapshot.data()
            if (doc) {
              commit('setUserProfile', doc)
            }
          })
      }),
      updateUserProfile: firestoreAction(({ state }) => {
        const userProfile = { ...state.userProfile }
        return db
          .collection('users')
          .doc(state.userProfile.uid)
          .set(userProfile)
          .then(() => {
            console.log('User profile updated.')
          })
      }),
      bindGameData: firestoreAction(({ bindFirestoreRef, state }) => {
        return bindFirestoreRef('game', db.collection('games').doc(state.currentGameId))
      }),
      getGamesByTeamId ({ state }, teamId) {
        return db
          .collection('games')
          .where('teamId', '==', teamId)
          .get()
          .then(snapshot => {
            if (snapshot.empty) {
              return null
            }
            const docs = snapshot.docs.map(doc => doc.data())
            return docs
          })
      },
      addGame: firestoreAction(({ state }, game) => {
        return db
          .collection('games')
          .add(game)
          .then(docRef => {
            return db
              .collection('games')
              .doc(docRef.id)
              .update({ id: docRef.id})
              .then(() => {
                return docRef.id
              })
          })
      }),
      updateGameStat: firestoreAction(({ state }, data) => {
        //Consider using transactions
        console.log(data)
        let payload
        if ('action' in data) {
          if (data.action == 'add') {
            payload = { [data.field] : firebase.firestore.FieldValue.arrayUnion(data.value) }
          } else {
            payload = { [data.field] : firebase.firestore.FieldValue.arrayRemove(data.value) }
          }
        } else {
          payload = { [data.field] : firebase.firestore.FieldValue.increment(data.value) }
        }
        return db.collection('games')
          .doc(state.game.id)
          .update(payload)
          .then(() => {
            console.log("Game stat added: ", data)
          })
      }),
      saveGame: firestoreAction(({ state }, game) => {
        return db
          .collection('games')
          .doc(game.id)
          .set(game)
          .then(() => {
            console.log('Game saved.')
            // Process game stats?
          })
      }),
      checkAuth({ commit }) {
        return new Promise((resolve) => {
          auth.onAuthStateChanged(async (user) => {
            if (user) {
              commit('setCurrentUser', user)
            } else {
              commit('setCurrentUser', null)
            }
            resolve(true)
          })
        })
      },
      clearData ({ commit }) {
        commit('setCurrentUser', null)
        commit('setUserProfile', { name: '' })
      }
    }
  })
