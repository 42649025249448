import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueHead from 'vue-head'
import { LayoutPlugin, ModalPlugin, CardPlugin, TablePlugin, NavPlugin, NavbarPlugin, ButtonPlugin, AlertPlugin, ProgressPlugin, FormInputPlugin, FormPlugin, BadgePlugin, FormSelectPlugin, FormGroupPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import '@/assets/css/styles.css'
import moment from 'moment'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MainHeader from '@/components/MainHeader'

Vue.config.productionTip = false
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS
// console.log(process.env.VUE_APP_ENV)

Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(TablePlugin)
Vue.use(AlertPlugin)
Vue.use(ProgressPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormPlugin)
Vue.use(BadgePlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormGroupPlugin)

Vue.use(VueHead)

library.add(faAngleLeft, faBars, faMinusCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('MainHeader', MainHeader)

Vue.filter('allcaps', (value) => {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(value).format('MM/DD/YYYY hh:mm')
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

