<template>
  <div id="app">
    <Navigation></Navigation>
    <router-view/>
  </div>
</template>

<script>

import Navigation from '@/components/MainHeader'

export default {
  name: 'App',
  components: { Navigation }
}
</script>
