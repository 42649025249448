import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'

// firebase init goes here
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_API_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

console.log(firebaseConfig)

firebase.initializeApp(firebaseConfig)
firebase.analytics();

// firebase utils
const auth = firebase.auth()
const analytics = firebase.analytics()
const db = firebase.firestore()
db.enablePersistence()
    .then(() => {
      console.log('Persistence enabled.')
    })
    .catch(function(err) {
        if (err.code == 'failed-precondition') {
            console.error('Multiple tabs open, persistence can only be enabled in one tab at a a time')
        } else if (err.code == 'unimplemented') {
          console.error('The current browser does not support all of the features required to enable persistence')
        }
    })
const currentUser = auth.currentUser

export {
  firebase,
  auth,
  db,
  currentUser,
  analytics
}