import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../store'

// import Login from '@/views/Login'
// import Signup from '@/views/Signup'
// import Callback from '@/components/Callback'
// import TeamList from '@/components/TeamList'
// import AddTeam from '@/components/AddTeam'
// import AddTeamDetail from '@/components/AddTeamDetail'
// import LinkTeamSnap from '@/components/LinkTeamSnap'
// import TeamDetail from '@/components/TeamDetail'
// import TeamSettings from '@/components/TeamSettings'
// import GameDetail from '@/components/GameDetail'
// import GameStatsInput from '@/components/GameStatsInput'
// import LiveStatsInput from '@/components/LiveStatsInput'

const Login = () => import('@/views/Login')
const Signup = () => import('@/views/Signup')
const Callback = () => import('@/components/Callback')
const TeamList = () => import('@/components/TeamList')
const AddTeam = () => import('@/components/AddTeam')
const AddTeamDetail = () => import('@/components/AddTeamDetail')
const LinkTeamSnap = () => import('@/components/LinkTeamSnap')
const TeamDetail = () => import('@/components/TeamDetail')
const TeamSettings = () => import('@/components/TeamSettings')
const GameDetail = () => import('@/components/GameDetail')
const GameStatsInput = () => import('@/components/GameStatsInput')
const LiveStatsInput = () => import('@/components/LiveStatsInput')


Vue.use(Router)

const checkProfileLoaded = (to, from, next) => {
  function proceed () {
    if (store.state.profileReady) {
      next()
    }
  }
  if (!store.state.profileReady) {
    store.watch(
      (state) => state.profileReady,
      (value) => {
        if (value === true) {
          proceed()
        }
      }
    )
  } else {
    proceed()
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/teamlist'
    },
    {
      path: '/',
      redirect: '/teamlist'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback
    },
    {
      path: '/teamlist',
      name: 'TeamList',
      component: TeamList,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/addteam',
      name: 'AddTeam',
      component: AddTeam,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/addteamdetail/:teamId',
      name: 'AddTeamDetail',
      component: AddTeamDetail,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/linkteamsnap',
      name: 'LinkTeamSnap',
      component: LinkTeamSnap,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/team/:teamId',
      name: 'TeamDetail',
      component: TeamDetail,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/team/:teamId/opponent/:opponentId',
      name: 'OpponentDetail',
      component: TeamDetail,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/team/:teamId/settings',
      name: 'teamsettings',
      component: TeamSettings,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/team/:teamId/game/:gameId',
      name: 'GameDetail',
      component: GameDetail,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/team/:teamId/game/:gameId/enter',
      name: 'GameStatsInput',
      component: GameStatsInput,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/team/:teamId/game/:gameId/live',
      name: 'LiveStatsInput',
      component: LiveStatsInput,
      beforeEnter: checkProfileLoaded,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('checkAuth').then(() => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const currentUser = store.state.currentUser

    if (requiresAuth && !currentUser) {
        next('/login')
    } else if (requiresAuth && currentUser) {
        next()
    } else {
        next()
    }
  })
})

export default router
