<template>
  <b-navbar class="d-flex justify-content-between" toggleable="md" type="dark" variant="info">

    <font-awesome-icon class="d-md-none" style="color: #fff;" v-if="nav.backLink" @click="back" icon="angle-left" ></font-awesome-icon>

    <div class="nav-bar-spacer"></div><!-- <b-button class="d-md-none" v-if="!nav.backLink" @click="back" :pressed="pressed" variant='info'>

    </b-button> -->

    <b-navbar-brand class='logo' to="/" >QuickStats</b-navbar-brand>

    <b-nav-item class='d-xs-none d-none d-md-block' :to='nav.teamLink'>{{ nav.teamName }}</b-nav-item>

    <b-collapse is-nav id="nav_collapse">

      <b-navbar-nav class="ml-auto">

        <b-nav-item-dropdown v-if="currentUser" right>
          <template slot="button-content">
            {{ userProfile.name }}
          </template>
          <b-dropdown-item @click="logout">Signout</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { store } from '../store'
import { auth } from '../firebaseConfig/index.js'

export default {
  name: 'MainHeader',
  data () {
    return {
      pressed: false,
    }
  },
  props: ['backLink', 'teamLink', 'teamName', 'opponentName', 'gameDate', 'isManager'],
  computed: {
    currentUser () {
      return store.state.currentUser
    },
    userProfile () {
      return store.state.userProfile
    },
    nav () {
      return store.state.nav
    }
  },
  methods: {
    back () {
      this.$router.push(this.nav.backLink)
      this.pressed = false
    },
    logout () {
      auth.signOut().then(() => {
        store.dispatch('clearData')
        this.$router.push({
          name: 'Login'
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }
}

</script>
